import React, { useState, useEffect } from 'react'

import { Card, CardHeader, CardContent, Button, Box, TextField, Table, TableHead, TableBody, TableRow, TableCell, FormControlLabel, Checkbox, Grid, Typography, Divider } from '@material-ui/core'
import { PriceBucketList } from './PriceBucketList'
import { commonStyle } from '../../../../styles'

import {
  AppNexus,
  Concert,
  Criteo,
  DistrictM,
  IndexExchange,
  Kargo,
  MediaNet,
  Pubmatic,
  Rubicon,
  Sharethrough,
  SpotX,
  Teads,
  Telaria,
  ThirtyThreeAcross,
  TripleLift,
  TrustX,
  Yahoo
} from './Vendors'

const AVAILABLE_BIDDERS = [
  {
    name: '33across',
    display: '33 Across',
    adapter: '33acrossBidAdapter'
  },
  {
    name: 'appnexus',
    display: 'AppNexus',
    adapter: 'appnexusBidAdapter'
  },
  {
    name: 'concert',
    display: 'Concert',
    adapter: 'concertBidAdapter'
  },
  {
    name: 'criteo',
    display: 'Criteo',
    adapter: 'criteoBidAdapter'
  },
  {
    name: 'districtm',
    display: 'DistrictM',
    adapter: ''
  },
  {
    name: 'ix',
    display: 'Index Exchange',
    adapter: 'ixBidAdapter'
  },
  {
    name: 'kargo',
    display: 'Kargo',
    adapter: 'kargoBidAdapter'
  },
  {
    name: 'medianet',
    display: 'MediaNet',
    adapter: 'medianetBidAdapter'
  },
  {
    name: 'pubmatic',
    display: 'Pubmatic',
    adapter: 'pubmaticBidAdapter'
  },
  {
    name: 'rubicon',
    display: 'Rubicon Project',
    adapter: 'rubiconBidAdapter'
  },
  {
    name: 'sharethrough',
    display: 'Sharethrough',
    adapter: 'sharethroughBidAdapter'
  },
  {
    name: 'spotx',
    display: 'SpotX',
    adapter: 'spotxBidAdapter'
  },
  {
    name: 'teads',
    display: 'Teads',
    adapter: 'teadsBidAdapter'
  },
  {
    name: 'telaria',
    display: 'Telaria',
    adapter: 'telariaBidAdapter'
  },
  {
    name: 'triplelift',
    display: 'Triplelift',
    adapter: 'tripleliftBidAdapter'
  },
  {
    name: 'trustx',
    display: 'TrustX',
    adapter: 'gridBidAdapter'
  },
  {
    name: 'yahoo',
    display: 'Yahoo',
    adapter: 'yahoosspBidAdapter'
  }
]

const AVAILABLE_MODULES = [
  {
    name: 'adpod',
    display: 'Adpod',
    adapter: 'adpod',
    required: true
  },
  {
    name: 'categoryTranslation',
    display: 'Category Translation',
    adapter: 'categoryTranslation',
    required: true
  },
  {
    name: 'concertAnalyticsAdapter',
    display: 'Concert Analytics',
    adapter: 'concertAnalyticsAdapter',
    required: false
  },
  {
    name: 'consentManagementUsp',
    display: 'CCPA',
    adapter: 'consentManagementUsp',
    required: true
  },
  {
    name: 'debugging',
    display: 'Debugging',
    adapter: 'debugging',
    required: true
  },
  {
    name: 'enrichmentFpdModule',
    display: 'Data Enrichment',
    adapter: 'enrichmentFpdModule',
    required: true
  },
  {
    name: 'fledgeForGpt',
    display: 'Fledge for GPT',
    adapter: 'fledgeForGpt',
    required: false
  },
  {
    name: 'freewheelAdserverVideo',
    display: 'FreeWheel Video',
    adapter: 'freeWheelAdserverVideo',
    required: true
  },
  {
    name: 'consentManagement',
    display: 'GDPR',
    adapter: 'consentManagement',
    required: true
  },
  {
    name: 'gpdrEnforcement',
    display: 'GDPR Enforcement',
    adapter: 'gdprEnforcement',
    required: true
  },
  {
    name: 'gptPreAuction',
    display: 'GPT Pre-Auction',
    adapter: 'gptPreAuction',
    required: true
  },
  {
    name: 'iasRtdProvider',
    display: 'IAS Real-Time Data',
    adapter: 'iasRtdProvider',
    required: false
  },
  {
    name: 'id5IdSystem',
    display: 'ID5 ID',
    adapter: 'id5IdSystem',
    required: false
  },
  {
    name: 'identityLinkIdSystem',
    display: 'IdentityLink ID',
    adapter: 'identityLinkIdSystem',
    required: false
  },
  {
    name: 'prebidServerBidAdapter',
    display: 'Prebid Server',
    adapter: 'prebidServerBidAdapter',
    required: true
  },
  {
    name: 'priceFloors',
    display: 'Price Floors',
    adapter: 'priceFloors',
    required: false    
  },
  {
    name: 'userId',
    display: 'User ID',
    adapter: 'userId',
    required: true
  }
  // {
  //   name: 'userIdTargeting',
  //   display: 'User ID Targeting',
  //   adapter: 'userIdTargeting'
  // }
]

const APPROVED_BIDDERS_BY_TYPE = {
  CLIENT: {
    DOM: ['33across', 'appnexus', 'concert', 'criteo', 'districtm', 'ix', 'kargo', 'medianet', 'pubmatic', 'rubicon', 'sharethrough', 'triplelift', 'trustx', 'yahoo'],
    INTL: ['33across', 'appnexus', 'concert', 'criteo', 'districtm', 'ix', 'kargo', 'medianet', 'pubmatic', 'rubicon', 'sharethrough', 'triplelift', 'trustx', 'yahoo']
  },
  OUTSTREAM: {
    DOM: ['appnexus', 'ix', 'pubmatic', 'teads'],
    INTL: ['appnexus', 'ix', 'pubmatic', 'teads']
  },
  S2S: {
    DOM: ['ix', 'sharethrough'],
    INTL: ['ix', 'sharethrough']
  },
  VIDEO: {
    DOM: ['appnexus', 'ix', 'rubicon', 'spotx', 'telaria'],
    INTL: ['appnexus', 'ix', 'rubicon', 'spotx', 'telaria']
  }
}

export const Prebid = ({ options, allOptions, originalBidders, originalModules, setOptions, setAllOptions, setBiddersChanged, fledgeVendors, toggleFledgeVendor, setModulesChanged, addComponent, removeComponent, errors, setErrors, validation }) => {
  const commonClasses = commonStyle()
  const { PREBID } = options
  let { BIDDERS, TIMEOUTS, SEND_ALL_BIDS, LIBRARY, FLOORS } = PREBID
  LIBRARY = LIBRARY || {}
  FLOORS = FLOORS || { 
    DISPLAY: { 
      DOM: { DESKTOP: 0.01, MOBILE: 0.01 }, 
      INTL: { DESKTOP: 0.01, MOBILE: 0.01 } 
    }, 
    VIDEO: { 
      DOM: { DESKTOP: 0.01, MOBILE: 0.01 }, 
      INTL: { DESKTOP: 0.01, MOBILE: 0.01 } 
    }
  }
  PREBID.FLOORS = FLOORS
  let { MODULES, VENDORS } = LIBRARY
  VENDORS = VENDORS || ['appnexus']
  MODULES = MODULES || ['categoryTranslation', 'consentManagement', 'prebidServerBidAdapter']
  PREBID.PRICE_BUCKETS = PREBID.PRICE_BUCKETS || {
    DOM: {
      DISPLAY: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01
        },
        {
          precision: 2,
          min: 5,
          max: 30,
          increment: 0.05
        },
        {
          precision: 2,
          min: 30,
          max: 115,
          increment: 1.0
        }
      ],
      VIDEO: [
        {
          precision: 2,
          min: 4,
          max: 50,
          increment: 1
        },
        {
          precision: 2,
          min: 50,
          max: 100,
          increment: 5
        }
      ],
      OUTSTREAM: [
        {
          precision: 2,
          min: 1,
          max: 50,
          increment: 1
        }
      ]
    },
    INTL: {
      DISPLAY: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01
        },
        {
          precision: 2,
          min: 5,
          max: 30,
          increment: 0.05
        },
        {
          precision: 2,
          min: 30,
          max: 50,
          increment: 1.0
        }
      ],
      VIDEO: [
        {
          precision: 2,
          min: 4,
          max: 50,
          increment: 1
        },
        {
          precision: 2,
          min: 50,
          max: 100,
          increment: 5
        }
      ],
      OUTSTREAM: [
        {
          precision: 2,
          min: 1,
          max: 50,
          increment: 1
        }
      ]
    }
  }
  BIDDERS.OUTSTREAM = BIDDERS.OUTSTREAM || { DOM: [], INTL: [] }
  BIDDERS.VIDEO = BIDDERS.VIDEO || { DOM: [], INTL: [] }
  const { DISPLAY, VIDEO } = TIMEOUTS
  const { NUMBER } = validation
  const { TIMEOUT, FLOOR } = NUMBER

  const [expandAllVendors, setExpandAllVendors] = useState(false)

  useEffect(() => {
    MODULES.forEach(module => {
      const adapterObj = AVAILABLE_MODULES.filter(mod => mod.name === module)[0]
      if (adapterObj) {
        addComponent(adapterObj.adapter)
      }
    })
    VENDORS.forEach(bidder => {
      const adapterObj = AVAILABLE_BIDDERS.filter(bid => bid.name === bidder)[0]
      if (adapterObj) {
        addComponent(adapterObj.adapter)
      }
    })
  }, [MODULES, VENDORS])

  function arraysEqual (a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    a.sort()
    b.sort()
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  const addTableRow = (items, itemsPerRow, onChange, type) => {
    const response = []
    while (items.length) {
      const currentRow = items.splice(0, itemsPerRow)
      response.push((
        <TableRow style={{ verticalAlign: 'top', border: 'none' }}>
          {currentRow.map((rowItem, index) => {
            const checked = (type === 'bidder' ? !!VENDORS.find(item => item === rowItem.name) : !!MODULES.find(item => item === rowItem.name))
            return (
              <React.Fragment key={`${rowItem.name}_${index}`}>
                <TableCell style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}>
                  <Box key={rowItem.name}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color='primary'
                          checked={checked || rowItem.required}
                          readOnly={rowItem.required}
                          disabled={rowItem.required}
                          onChange={async (event) => {
                            const checked = event.target.checked
                            if (checked) {
                              if (type === 'bidder') {
                                PREBID.LIBRARY = PREBID.LIBRARY || {}
                                PREBID.LIBRARY.VENDORS = [...VENDORS, rowItem.name]
                                addComponent(rowItem.adapter)
                              }
                              if (type === 'module') {
                                PREBID.LIBRARY = PREBID.LIBRARY || {}
                                PREBID.LIBRARY.MODULES = [...MODULES, rowItem.name]
                                addComponent(rowItem.adapter)
                              }
                            } else {
                              if (type === 'bidder') {
                                PREBID.LIBRARY = PREBID.LIBRARY || {}
                                PREBID.LIBRARY.VENDORS = VENDORS.filter(item => item !== rowItem.name)
                                removeComponent(rowItem.adapter)
                              }
                              if (type === 'module') {
                                PREBID.LIBRARY = PREBID.LIBRARY || {}
                                PREBID.LIBRARY.MODULES = MODULES.filter(item => item !== rowItem.name)
                                removeComponent(rowItem.adapter)
                              }
                            }
                            if (type === 'bidder' ? !arraysEqual(PREBID.LIBRARY.VENDORS, originalBidders) : !arraysEqual(PREBID.LIBRARY.MODULES, originalModules)) {
                              await onChange(true)
                            } else {
                              await onChange(false)
                            }
                            VENDORS.sort((a, b) => {
                              if (a.display > b.display) return 1
                              if (b.display > a.display) return -1
                              return 0
                            })
                            MODULES.sort((a, b) => {
                              if (a.display > b.display) return 1
                              if (b.display > a.display) return -1
                              return 0
                            })
                            setOptions({ ...options, PREBID })
                          }}
                        />
                      )}
                      label={rowItem.display}
                    />
                  </Box>
                </TableCell>
              </React.Fragment>
            )
          })}
        </TableRow>
      ))
    }
    return response
  }

  const addFledgeTableRow = (items, itemsPerRow, onChange, type, fledgeVendors) => {
    const response = []
    const filteredItems = items.filter(rowItem => !!VENDORS.find(item => item === rowItem.name))
    while (filteredItems.length) {
      const currentRow = filteredItems.splice(0, itemsPerRow)
      response.push((
        <TableRow style={{ verticalAlign: 'top', border: 'none' }}>
          {currentRow.map((rowItem, index) => {
            const checked = fledgeVendors.has(rowItem.name)
            return (
              <React.Fragment key={`${rowItem.name}_${index}`}>
                <TableCell style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}>
                  <Box key={rowItem.name}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color='primary'
                          checked={checked || rowItem.required}
                          readOnly={rowItem.required}
                          disabled={rowItem.required}
                          onChange={async (event) => {
                            toggleFledgeVendor(rowItem.name)
                            setOptions({ ...options, PREBID })
                          }}
                        />
                      )}
                      label={rowItem.display}
                    />
                  </Box>
                </TableCell>
              </React.Fragment>
            )
          })}
        </TableRow>
      ))
    }
    return response
  }

  const displayModulesTable = () => {
    return (
      <Table size='small' aria-label='prebid-bidder-table'>
        <TableHead>
          <TableRow>
            <TableCell colSpan='5' style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Prebid Modules</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addTableRow(JSON.parse(JSON.stringify(AVAILABLE_MODULES)), 4, setModulesChanged, 'module')}
        </TableBody>
      </Table>
    )
  }

  const displayBidderTable = () => {
    return (
      <Table size='small' aria-label='prebid-bidder-table'>
        <TableHead>
          <TableRow>
            <TableCell colSpan='5' style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5' bol>Approved Vendors</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addTableRow(JSON.parse(JSON.stringify(AVAILABLE_BIDDERS)), 5, setBiddersChanged, 'bidder')}
        </TableBody>
      </Table>
    )
  }

  const displayBidderByTypeTable = () => {
    return (
      <Table size='small' aria-label='prebid-bidder-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Bidders By Type</Typography></TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Outstream</TableCell>
            <TableCell>Server-to-Server</TableCell>
            <TableCell>Video</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ verticalAlign: 'top' }}>
            <TableCell>Domestic</TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.CLIENT.DOM.map(vendor => {
                const checked = !!BIDDERS.CLIENT.DOM.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.CLIENT.DOM = [...PREBID.BIDDERS.CLIENT.DOM, vendor]
                              } else {
                                PREBID.BIDDERS.CLIENT.DOM = PREBID.BIDDERS.CLIENT.DOM.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.CLIENT.DOM.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.OUTSTREAM.DOM.map(vendor => {
                const checked = !!BIDDERS.OUTSTREAM.DOM.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.OUTSTREAM.DOM = [...PREBID.BIDDERS.OUTSTREAM.DOM, vendor]
                              } else {
                                PREBID.BIDDERS.OUTSTREAM.DOM = PREBID.BIDDERS.OUTSTREAM.DOM.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.OUTSTREAM.DOM.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.S2S.DOM.map(vendor => {
                const checked = !!BIDDERS.S2S.DOM.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.S2S.DOM = [...PREBID.BIDDERS.S2S.DOM, vendor]
                              } else {
                                PREBID.BIDDERS.S2S.DOM = PREBID.BIDDERS.S2S.DOM.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.S2S.DOM.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.VIDEO.DOM.map(vendor => {
                const checked = !!BIDDERS.VIDEO.DOM.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.VIDEO.DOM = [...PREBID.BIDDERS.VIDEO.DOM, vendor]
                              } else {
                                PREBID.BIDDERS.VIDEO.DOM = PREBID.BIDDERS.VIDEO.DOM.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.VIDEO.DOM.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
          </TableRow>
          <TableRow style={{ verticalAlign: 'top' }}>
            <TableCell>International</TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.CLIENT.INTL.map(vendor => {
                const checked = !!BIDDERS.CLIENT.INTL.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.CLIENT.INTL = [...PREBID.BIDDERS.CLIENT.INTL, vendor]
                              } else {
                                PREBID.BIDDERS.CLIENT.INTL = PREBID.BIDDERS.CLIENT.INTL.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.CLIENT.INTL.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.OUTSTREAM.INTL.map(vendor => {
                const checked = !!BIDDERS.OUTSTREAM.INTL.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.OUTSTREAM.INTL = [...PREBID.BIDDERS.OUTSTREAM.INTL, vendor]
                              } else {
                                PREBID.BIDDERS.OUTSTREAM.INTL = PREBID.BIDDERS.OUTSTREAM.INTL.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.OUTSTREAM.INTL.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.S2S.INTL.map(vendor => {
                const checked = !!BIDDERS.S2S.INTL.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.S2S.INTL = [...PREBID.BIDDERS.S2S.INTL, vendor]
                              } else {
                                PREBID.BIDDERS.S2S.INTL = PREBID.BIDDERS.S2S.INTL.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.S2S.INTL.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
            <TableCell>
              {APPROVED_BIDDERS_BY_TYPE.VIDEO.INTL.map(vendor => {
                const checked = !!BIDDERS.VIDEO.INTL.find(item => item === vendor)
                const disabled = !VENDORS.find(item => item === vendor)
                if (!disabled) {
                  return (
                    <Box key={vendor}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            disabled={disabled}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                PREBID.BIDDERS.VIDEO.INTL = [...PREBID.BIDDERS.VIDEO.INTL, vendor]
                              } else {
                                PREBID.BIDDERS.VIDEO.INTL = PREBID.BIDDERS.VIDEO.INTL.filter(item => item !== vendor)
                              }
                              PREBID.BIDDERS.VIDEO.INTL.sort()
                              setOptions({ ...options, PREBID })
                            }}
                          />
                        )}
                        label={vendor}
                      />
                    </Box>
                  )
                } else { return '' }
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayPriceFloorsTable = () => {
    return (
      <Table size='small' aria-label='IX-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Price Floors</Typography></TableCell>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Domestic</Typography></TableCell>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>International</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Display</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.DISPLAY.DOM.DESKTOP}
                    error={errors.PREBID_FDDD}
                    helperText={errors.PREBID_FDDD && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.DISPLAY.DOM.DESKTOP = value
                      setErrors({ ...errors, PREBID_FDDD: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.DISPLAY.DOM.MOBILE}
                    error={errors.PREBID_FDDM}
                    helperText={errors.PREBID_FDDM && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.DISPLAY.DOM.MOBILE = value
                      setErrors({ ...errors, PREBID_FDIM: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.DISPLAY.INTL.DESKTOP}
                    error={errors.PREBID_FDID}
                    helperText={errors.PREBID_FDID && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.DISPLAY.INTL.DESKTOP = value
                      setErrors({ ...errors, PREBID_FDID: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.DISPLAY.INTL.MOBILE}
                    error={errors.PREBID_FDIM}
                    helperText={errors.PREBID_FDIM && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.DISPLAY.INTL.MOBILE = value
                      setErrors({ ...errors, PREBID_FDIM: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Video</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.VIDEO.DOM.DESKTOP}
                    error={errors.PREBID_FVDD}
                    helperText={errors.PREBID_FVDD && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.VIDEO.DOM.DESKTOP = value
                      setErrors({ ...errors, PREBID_FVDD: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.VIDEO.DOM.MOBILE}
                    error={errors.PREBID_FVDM}
                    helperText={errors.PREBID_FVDM && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.VIDEO.DOM.MOBILE = value
                      setErrors({ ...errors, PREBID_FVDM: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.VIDEO.INTL.DESKTOP}
                    error={errors.PREBID_FVID}
                    helperText={errors.PREBID_FVID && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.VIDEO.INTL.DESKTOP = value
                      setErrors({ ...errors, PREBID_FVID: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: FLOOR.min,
                      max: FLOOR.max
                    }}
                    value={FLOORS.VIDEO.INTL.MOBILE}
                    error={errors.PREBID_FVIM}
                    helperText={errors.PREBID_FVIM && FLOOR.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.FLOORS.VIDEO.INTL.MOBILE = value
                      setErrors({ ...errors, PREBID_FVIM: value < FLOOR.min || value > FLOOR.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayPriceBucketTable = () => {
    return (
      <Table size='small' aria-label='IX-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Price Buckets</Typography></TableCell>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Domestic</Typography></TableCell>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>International</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Display</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.DOM?.DISPLAY}
                path='PREBID.PRICE_BUCKETS.DOM.DISPLAY'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.INTL?.DISPLAY}
                path='PREBID.PRICE_BUCKETS.INTL.DISPLAY'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Video</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.DOM?.VIDEO}
                path='PREBID.PRICE_BUCKETS.DOM.VIDEO'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.INTL?.VIDEO}
                path='PREBID.PRICE_BUCKETS.INTL.VIDEO'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h6'>Outstream</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.DOM?.OUTSTREAM}
                path='PREBID.PRICE_BUCKETS.DOM.OUTSTREAM'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <PriceBucketList
                object={PREBID.PRICE_BUCKETS?.INTL?.OUTSTREAM}
                path='PREBID.PRICE_BUCKETS.INTL.OUTSTREAM'
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayFledgeTable = () => {
    return (
      <Table size='small' aria-label='fledgeForGpt-vendor-table'>
        <TableHead>
          <TableRow>
            <TableCell colSpan='5' style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5' bol>Fledge For GPT</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addFledgeTableRow(JSON.parse(JSON.stringify(AVAILABLE_BIDDERS)), 5, toggleFledgeVendor, 'bidder', fledgeVendors)}
        </TableBody>
      </Table>
    )
  }

  const displayTimeoutTable = () => {
    return (
      <Table size='small' aria-label='prebid-timeout-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Timeouts</Typography></TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Display</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={DISPLAY.DESKTOP}
                    error={errors.PREBID_TDD}
                    helperText={errors.PREBID_TDD && TIMEOUT.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.TIMEOUTS.DISPLAY.DESKTOP = value
                      setErrors({ ...errors, PREBID_TDD: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={DISPLAY.MOBILE}
                    error={errors.PREBID_TDM}
                    helperText={errors.PREBID_TDM && TIMEOUT.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.TIMEOUTS.DISPLAY.MOBILE = value
                      setErrors({ ...errors, PREBID_TDM: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Video</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Default'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={VIDEO.DEFAULT}
                    error={errors.PREBID_TVD}
                    helperText={errors.PREBID_TVD && TIMEOUT.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.TIMEOUTS.VIDEO.DEFAULT = value
                      setErrors({ ...errors, PREBID_TVD: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Midroll'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={VIDEO.MIDROLL}
                    error={errors.PREBID_TVM}
                    helperText={errors.PREBID_TVM && TIMEOUT.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.TIMEOUTS.VIDEO.MIDROLL = value
                      setErrors({ ...errors, PREBID_TVM: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Refresh'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={VIDEO.REFRESH}
                    error={errors.PREBID_TVR}
                    helperText={errors.PREBID_TVR && TIMEOUT.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.TIMEOUTS.VIDEO.REFRESH = value
                      setErrors({ ...errors, PREBID_TVR: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displaySendBidTable = () => {
    return (
      <Table size='small' aria-label='prebid-send-bid-table'>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography style={{ fontWeight: 800 }} variant='h5'>Send All Bids</Typography></TableCell>
            <TableCell>
              <Grid container spacing={3}>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color='primary'
                        checked={SEND_ALL_BIDS.DOM}
                        onChange={(event) => {
                          PREBID.SEND_ALL_BIDS.DOM = event.target.checked
                          setOptions({ ...options, PREBID })
                        }}
                      />
                    )}
                    label='Domestic'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color='primary'
                        checked={SEND_ALL_BIDS.INTL}
                        onChange={(event) => {
                          PREBID.SEND_ALL_BIDS.INTL = event.target.checked
                          setOptions({ ...options, PREBID })
                        }}
                      />
                    )}
                    label='International'
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayVendorConfigurationTable = (hasFledge) => {
    return (
      <Card>
        <CardHeader
          title={<Typography style={{ fontWeight: 800 }} variant='h5'>Vendor Configuration</Typography>}
          action={
            <Button
              color='primary'
              variant='text'
              onClick={() => setExpandAllVendors(!expandAllVendors)}
            >
              {expandAllVendors ? 'Collapse All' : 'Expand All'}
            </Button>
          }
        />
        <Divider />
        <CardContent className={commonClasses.cardBkClr}>
          {VENDORS.indexOf('33across') >= 0
            ? (<ThirtyThreeAcross
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('appnexus') >= 0
            ? (<AppNexus
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('concert') >= 0
            ? (<Concert
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('criteo') >= 0
            ? (<Criteo
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('districtm') >= 0
            ? (<DistrictM
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('ix') >= 0
            ? (<IndexExchange
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('kargo') >= 0
            ? (<Kargo
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('medianet') >= 0
            ? (<MediaNet
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('pubmatic') >= 0
            ? (<Pubmatic
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('rubicon') >= 0
            ? (<Rubicon
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('sharethrough') >= 0
            ? (<Sharethrough
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('spotx') >= 0
            ? (<SpotX
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
          {VENDORS.indexOf('teads') >= 0
            ? (<Teads
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                hasFledge={hasFledge}
                validation={validation}
               />)
            : ''}
          {VENDORS.indexOf('telaria') >= 0
            ? (<Telaria
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                hasFledge={hasFledge}
                validation={validation}
               />)
            : ''}
          {VENDORS.indexOf('triplelift') >= 0
            ? (<TripleLift
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                hasFledge={hasFledge}
                validation={validation}
               />)
            : ''}
          {VENDORS.indexOf('trustx') >= 0
            ? (<TrustX
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                hasFledge={hasFledge}
                validation={validation}
               />)
            : ''}
          {VENDORS.indexOf('yahoo') >= 0
            ? (<Yahoo
                expandAllVendors={expandAllVendors}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                hasFledge={hasFledge}
               />)
            : ''}
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayModulesTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayBidderTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayBidderByTypeTable()}</Grid>
          {MODULES.indexOf('fledgeForGpt') >= 0
            ? (<Grid className={commonClasses.mb_3} item xs={12}>{displayFledgeTable()}</Grid>)
            : ''
          }
          <Grid className={commonClasses.mb_3} item xs={12}>{displayTimeoutTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displaySendBidTable()}</Grid>
          {MODULES.indexOf('priceFloors') >= 0
            ? (<Grid className={commonClasses.mb_3} item xs={12}>{displayPriceFloorsTable()}</Grid>)
            : ''
          }
          <Grid className={commonClasses.mb_3} item xs={12}>{displayPriceBucketTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayVendorConfigurationTable(MODULES.indexOf('fledgeForGpt') >= 0)}</Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
