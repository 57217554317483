import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import PropTypes from 'prop-types'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  hidden: {
    display: 'none',
    visibility: 'collapse'
  },
  selected: {
    backgroundColor: '#333',
    color: '#FFF'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bolder'
  },
  spacer: { margin: theme.spacing(1) },
  saveButton: { color: '#2D2' },
  argSaveButton: { backgroundColor: '#2A2', color: '#FFF' },
  argDeleteButton: { backgroundColor: '#D22', color: '#FFF' },
  dialogCloseButton: { backgroundColor: '#222', color: '#FFF' },
  deleteButton: { color: '#D22' },
  plainText: { fontSize: theme.typography.pxToRem(18) },
  bolder: { fontWeight: 'bolder' },
  buttonGroup: {
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 5
  }
}))

const AdFuelModulesDialog = (props) => {
  // // console.log('Module Dialog Props: ', props)
  const classes = useStyles()
  const initialState = {
    name: '',
    description: '',
    priority: 9,
    versions: [],
    createdBy: '',
    createdOn: Date.now(),
    modifiedBy: '',
    modifiedOn: Date.now()
  }

  const [module, setModule] = useState(props.module || initialState)
  const [moduleName, setModuleName] = useState(module.name || '')
  const [moduleDescription, setModuleDescription] = useState(module.description || '')
  const [moduleURL, setModuleURL] = useState(module.url)
  const [moduleConfigurationKey, setModuleConfigurationKey] = useState(module.configurationKey)
  const [modulePriority, setModulePriority] = useState(module.priority || 9)
  const [moduleVersions, setModuleVersions] = useState(module.versions || [])
  const [moduleIsConfigurable, setModuleIsConfigurable] = useState(typeof module.isConfigurable === 'undefined' ? false : module.isConfigurable)
  const [open, setOpen] = useState(props.open)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setOpen(props.open || false)
  }, [props.open])

  useEffect(() => {
    setModule(props.module)
    setModuleName(props.module.name)
    setModuleURL(props.module.url)
    setModuleIsConfigurable(props.module.isConfigurable)
    setModuleConfigurationKey(props.module.configurationKey)
    setModuleDescription(props.module.description)
    setModulePriority(props.module.priority)
    setModuleVersions(props.module.versions)
  }, [props.module])

  const handleClose = e => {
    if (isDirty) {
      swal.fire({
        title: 'Are you sure?',
        text: 'You have unsaved changes to the selected argument.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willClose => {
        if (willClose.isConfirmed) {
          setOpen(false)
          if (props.handleClose) {
            // console.log('deferring to parent component: ', `${props.module.name}`)
            props.handleClose(props.module)
          }
        }
      })
    } else {
      setOpen(false)
      if (props.handleClose) {
        // console.log('deferring to parent component: ', `${props.module.name}`)
        props.handleClose(props.module)
      }
    }
  }

  const handleSave = e => {
    if (isDirty) {
      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willSave => {
        if (willSave.isConfirmed) {
          console.log('Fetching URL for validation...')          
          fetch(moduleURL).then(response => {
            if (response.status === 404) {
              swal.fire({ text: 'The URL does not exist.  Please try check it and try again.' })
            } else {
              setIsDirty(false)
              if (props.handleSave) {
                props.handleSave(moduleName, moduleDescription, moduleURL, moduleIsConfigurable, moduleConfigurationKey, modulePriority, moduleVersions)
              }  
            }
          })
        }
      })
    } else {
      swal.fire({
        text: 'There are no unsaved changes.'
      })
    }
  }

  const changeModuleName = e => {
    setModuleName(e.target.value)
    setIsDirty(true)
  }

  const changeModuleIsConfigurable = e => {
    console.log('Setting Module Is Configurable: ', e.target.checked)
    setModuleIsConfigurable(e.target.checked)
    setIsDirty(true)
  }

  const changeModuleConfigurationKey = e => {
    setModuleConfigurationKey(e.target.value)
    setIsDirty(true)
  }

  const changeModuleDescription = e => {
    setModuleDescription(e.target.value)
    setIsDirty(true)
  }

  const changeModuleURL = e => {
    setModuleURL(e.target.value)
    setIsDirty(true)
  }

  const changeModulePriority = e => {
    setModulePriority(e.target.value)
    setIsDirty(true)
  }

  const mainForm = () => {
    console.log('Module is configurable ?', moduleIsConfigurable)
    return (
      <form autoComplete='off'>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Name:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id='module-name'
              style={{ width: '90%' }}
              variant='outlined'
              value={moduleName || ''}
              required
              label=''
              onChange={changeModuleName}
            />
          </Grid>
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Description:</div>
          </Grid>
          <Grid item xs={9}>
            <TextareaAutosize
              id='module-description'
              rowsMin='3'
              style={{ width: '90%', fontFamily: 'Turner-Regular', fontSize: '16px' }}
              variant='outlined'
              value={moduleDescription || ''}
              required
              onChange={changeModuleDescription}
            />
          </Grid>        
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Is Configurable:</div>
          </Grid>
          <Grid item xs={9} align='left' >
            <Checkbox
              id='module-isConfigurable'
              variant='outlined'
              checked={moduleIsConfigurable !== false}
              required
              label=''
              onChange={changeModuleIsConfigurable}
            />
          </Grid>
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Configuration Key:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id='module-configuration-key'
              style={{ width: '90%' }}
              variant='outlined'
              value={moduleConfigurationKey || ''}
              required
              label=''
              onChange={changeModuleConfigurationKey}
            />
          </Grid>
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Root URL:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id='module-url'
              style={{ width: '90%' }}
              variant='outlined'
              value={moduleURL || ''}
              required
              label=''
              onChange={changeModuleURL}
            />
          </Grid>
          <Grid item xs={3} align='right'>
            <div className={classes.formLabel}>Priority:</div>
          </Grid>
          <Grid item xs={9}>
            <Select
              required
              value={modulePriority || 0}
              onChange={changeModulePriority}
            >
              {[...Array(101).keys()].map(argType => (
                <MenuItem key={`priority_${argType}`} value={argType}>
                  {argType}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>General Module Information</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mainForm()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Close
        </Button>
        <Button onClick={handleSave} color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AdFuelModulesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  module: PropTypes.object
}

export default AdFuelModulesDialog
