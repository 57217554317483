import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDeviceCapabilities, getDeviceCategories, getOperatingSystems, getAllCustomTargets, getAllPlacements, getAllAdvertisers, fetchAllAdUnits } from '../../modules/DFPAdUnitSlice'

import { request } from '../../utilities/request'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Link, Grid, IconButton, List, ListItem, MenuItem, Select, Typography, Checkbox, FormControlLabel, Box, InputAdornment, makeStyles, Container, Card, CardActions, CardHeader, Divider, CardContent, Button, FormGroup, TextField, LinearProgress } from '@material-ui/core'
import MaterialTable from 'material-table'
import { TableIcons, CustomDialog } from '../ui'
import { addClasses, commonStyle } from '../../styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import PublishIcon from '@material-ui/icons/Publish'
import InfoIcon from '@material-ui/icons/Info'

import { LineItemTargeting } from './index'

const swal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
  displayFlex: {
    display: 'flex'
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end'
  },
  justifyFlexStart: {
    justifyContent: 'flex-start'
  },
  justifySpaceBetween: {
    justifyContent: 'space-between'
  },
  dropdown: {
    minWidth: '200px'
  },
  flexGrow: {
    flexGrow: 1
  },
  fullWidth: {
    width: '100%'
  },
  plainText: {
    fontSize: theme.typography.pxToRem(18)
  },
  heading: {
    marginTop: theme.spacing(3),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    '& *': {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightRegular
    }
  }
}))

// ==========================================================================================
//  _   _ _____ _     ____  _____ ____    _____ _   _ _   _  ____ _____ ___ ___  _   _ ____
// | | | | ____| |   |  _ \| ____|  _ \  |  ___| | | | \ | |/ ___|_   _|_ _/ _ \| \ | / ___|
// | |_| |  _| | |   | |_) |  _| | |_) | | |_  | | | |  \| | |     | |  | | | | |  \| \___ \
// |  _  | |___| |___|  __/| |___|  _ <  |  _| | |_| | |\  | |___  | |  | | |_| | |\  |___) |
// |_| |_|_____|_____|_|   |_____|_| \_\ |_|    \___/|_| \_|\____| |_| |___\___/|_| \_|____/
//
// ==========================================================================================

const convertObjectToArray = (object) => {
  return Object.entries(object).map(entry => entry[1])
}

const mergeArrayIntoObject = (array, fields) => {
  const result = {}
  array.forEach((item, index) => {
    let key = JSON.parse(JSON.stringify(fields[index]))
    console.log('key/item: ', { key, item })    
    result[key] = item
  })
  return result
}

const indexArrayByObjectName = (array) => {
  const result = {}
  array.forEach(item => {
    result[item.name] = item
  })
  return result
}

const convertToGAMDateTime = (dateTime) => {
  return {
    date: {
      year: dateTime.getFullYear(),
      month: dateTime.getMonth() + 1,
      day: dateTime.getDate()
    },
    hour: 0,
    minute: 0,
    second: 0,
    timeZoneId: 'America/New_York'
  }
}

const cleanCut = (string = '', method = ',') => {
  return string.split(method).map(item => item.trim())
}

const formatKey = (key) => {
  return key.replace('!', '')
}

const range = (start, end) => (new Array(end - start + 1)).fill(undefined).map((_, i) => (i + start).toString())
const formatText = text => text.replace(/_/g, ' ').toLowerCase().replace(/(^\w|\s\w)/g, match => match.toUpperCase())

/* OTHER TARGETINGS */
const setOtherTargetings = (source, dest) => {

  console.log('Setting Other Targeting: ', { source, dest })

  dest.technologyTargeting.deviceCapabilityTargeting.targetedDeviceCapabilities = source.customTarget.deviceCapabilityTargeting.includeList.map(item => { return { id: item?.id, name: item?.devicecapabilityname } })
  dest.technologyTargeting.deviceCapabilityTargeting.excludedDeviceCapabilities = source.customTarget.deviceCapabilityTargeting.excludeList.map(item => { return { id: item?.id, name: item?.devicecapabilityname } })

  dest.technologyTargeting.deviceCategoryTargeting.targetedDeviceCategories = source.customTarget.deviceCategoryTargeting.includeList.map(item => { return { id: item?.id, name: item?.devicecategoryname } })
  dest.technologyTargeting.deviceCategoryTargeting.excludedDeviceCategories = source.customTarget.deviceCategoryTargeting.excludeList.map(item => { return { id: item?.id, name: item?.devicecategoryname } })

  dest.technologyTargeting.operatingSystemTargeting.isTargeted = source.customTarget.operatingSystemTargeting.isTargeted
  dest.technologyTargeting.operatingSystemTargeting.operatingSystems = source.customTarget.operatingSystemTargeting.list.map(item => { return { id: item?.id, name: item?.operatingsystemname } })

}

// ===============================================================================
//  ____    _  _____  _         _    _   _ ____    _____ _   _ _   _ __  __ ____
// |  _ \  / \|_   _|/ \       / \  | \ | |  _ \  | ____| \ | | | | |  \/  / ___|
// | | | |/ _ \ | | / _ \     / _ \ |  \| | | | | |  _| |  \| | | | | |\/| \___ \
// | |_| / ___ \| |/ ___ \   / ___ \| |\  | |_| | | |___| |\  | |_| | |  | |___) |
// |____/_/   \_\_/_/   \_\ /_/   \_\_| \_|____/  |_____|_| \_|\___/|_|  |_|____/
//
// ===============================================================================

const GAM_BASE_URL = 'https://admanager.google.com/8663477'
const generalInformation = {
  title: 'How to Use',
  instructions: [
    'Click download template.',
    'Modify CSV file template by adding Line Item data.',
    'Click choose file and select the modified CSV file.',
    'Fill out the form.',
    'Click upload.'
  ]
}
const csvFileInformation = {
  title: 'Modifying the CSV File',
  instructions: [
    'name - must be unique.',
    'sizes - use the semicolon symbol(;) to separate multiple entries. Each entry is in the format of width x height.',
    'rate - USD in currency format.',
    'targetKeyValues - use the semicolon symbol(;) to separate multiple entries. Each entry is in the format of key = values. Use the add symbol(+) to separate multiple values.',
    'geoTargets - use the semicolon symbol(;) to separate multiple entries. Use the exclamation symbol(!) to denote NOT followed by location. Example: !US. Locations available: US-CA, US, CA, GB.'
  ]
}
const formInformation = {
  title: 'Filling out the Form',
  instructions: [
    'Order Name - must be unique and not already in use.',
    'Advertiser - type minimum of 3 characters to generate list.',
    'Line Item Defaults - properties here will apply to all line items.',
    'Ad Targetings - choose ad unit and sizes if applicable',
    'Line Item Targeting - choose settings if applicable',
    'Creative Targetings - create targetings and choose settings if applicable',
    '** Note - YOU MUST UPLOAD CSV FILE TO CREATE LINE ITEMS **'
  ]
}

const lineItemTypeEnum = {
  SPONSORSHIP: 'SPONSORSHIP',
  STANDARD: 'STANDARD',
  NETWORK: 'NETWORK',
  BULK: 'BULK',
  PRICE_PRIORITY: 'PRICE_PRIORITY',
  HOUSE: 'HOUSE',
  CLICK_TRACKING: 'CLICK_TRACKING',
  ADSENSE: 'ADSENSE',
  AD_EXCHANGE: 'AD_EXCHANGE',
  BUMPER: 'BUMPER'
}

const costTypeEnum = {
  CPA: 'CPA',
  CPC: 'CPC',
  CPD: 'CPD',
  CPM: 'CPM',
  VCPM: 'VCPM',
  UNKNOWN: 'UNKNOWN'
}

const goalTypeEnum = {
  DAILY: 'DAILY',
  LIFETIME: 'LIFETIME',
  NONE: 'NONE'
}

const unitTypeEnum = {
  SHARE_OF_VOICE: 'SHARE_OF_VOICE',
  IMPRESSIONS: 'IMPRESSIONS',
  CLICK_THROUGH_CPA_CONVERSIONS: 'CLICK_THROUGH_CPA_CONVERSIONS',
  VIEW_THROUGH_CPA_CONVERSIONS: 'VIEW_THROUGH_CPA_CONVERSIONS',
  TOTAL_CPA_CONVERSIONS: 'TOTAL_CPA_CONVERSIONS',
  VIEWABLE_IMPRESSIONS: 'VIEWABLE_IMPRESSIONS',
  UNKNOWN: 'UNKNOWN'
}

const lineItemTypeList = [
  lineItemTypeEnum.SPONSORSHIP,
  lineItemTypeEnum.STANDARD,
  lineItemTypeEnum.NETWORK,
  lineItemTypeEnum.BULK,
  lineItemTypeEnum.PRICE_PRIORITY,
  lineItemTypeEnum.HOUSE,
  lineItemTypeEnum.CLICK_TRACKING,
  lineItemTypeEnum.ADSENSE,
  lineItemTypeEnum.AD_EXCHANGE,
  lineItemTypeEnum.BUMPER
]

const lineItemOptions = {
  SPONSORSHIP: {
    priorityOptions: {
      default: 4,
      min: 2,
      max: 5
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC, costTypeEnum.CPD, costTypeEnum.CPA],
    goalTypes: [goalTypeEnum.DAILY],
    unitTypes: [unitTypeEnum.UNKNOWN],
    units: 100
  },
  STANDARD: {
    priorityOptions: {
      default: 8,
      min: 6,
      max: 10
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC, costTypeEnum.CPA, costTypeEnum.VCPM],
    goalTypes: [goalTypeEnum.LIFETIME],
    unitTypes: [unitTypeEnum.IMPRESSIONS, unitTypeEnum.VIEWABLE_IMPRESSIONS],
    units: 100
  },
  NETWORK: {
    priorityOptions: {
      default: 12,
      min: 11,
      max: 14
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC, costTypeEnum.CPD, costTypeEnum.CPA],
    goalTypes: [goalTypeEnum.DAILY],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  BULK: {
    priorityOptions: {
      default: 12,
      min: 11,
      max: 14
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC, costTypeEnum.CPA],
    goalTypes: [goalTypeEnum.LIFETIME],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  PRICE_PRIORITY: {
    priorityOptions: {
      default: 12,
      min: 11,
      max: 14
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC],
    goalTypes: [goalTypeEnum.LIFETIME, goalTypeEnum.DAILY, goalTypeEnum.NONE],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  HOUSE: {
    priorityOptions: {
      default: 16,
      min: 15,
      max: 16
    },
    costTypes: [costTypeEnum.CPM, costTypeEnum.CPC],
    goalTypes: [goalTypeEnum.DAILY],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  CLICK_TRACKING: {
    priorityOptions: {
      default: 16,
      min: 1,
      max: 16
    },
    costTypes: [costTypeEnum.UNKNOWN],
    goalTypes: [goalTypeEnum.LIFETIME, goalTypeEnum.NONE],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  ADSENSE: {
    priorityOptions: {
      default: 12,
      min: 1,
      max: 16
    },
    costTypes: [costTypeEnum.UNKNOWN],
    goalTypes: [goalTypeEnum.LIFETIME, goalTypeEnum.DAILY],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  AD_EXCHANGE: {
    priorityOptions: {
      default: 12,
      min: 1,
      max: 16
    },
    costTypes: [costTypeEnum.UNKNOWN],
    goalTypes: [goalTypeEnum.LIFETIME, goalTypeEnum.DAILY, goalTypeEnum.NONE],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  },
  BUMPER: {
    priorityOptions: {
      default: 16,
      min: 15,
      max: 16
    },
    costTypes: [costTypeEnum.UNKNOWN],
    goalTypes: [goalTypeEnum.DAILY],
    unitTypes: [unitTypeEnum.IMPRESSIONS],
    units: 100
  }
}

const initialCustomTarget = {
  deviceCapabilityTargeting: { includeList: [], excludeList: [] },
  deviceCategoryTargeting: { includeList: [], excludeList: [] },
  operatingSystemTargeting: { list: [], isTargeted: true },
  exclude: false,
  value: null,
  key: null
}

const initialLineItem = {
  orderId: null,
  name: 'template_sample',
  startDateTimeType: 'IMMEDIATELY',
  endDateTime: convertToGAMDateTime(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)),
  unlimitedEndDateTime: false,
  creativeRotationType: 'EVEN',
  lineItemType: 'SPONSORSHIP',
  priority: '4',
  costPerUnit: { currencyCode: 'USD', microAmount: 0 },
  costType: 'CPM',
  creativePlaceholders: [],
  activityAssociations: [],
  disableSameAdvertiserCompetitiveExclusion: true,
  primaryGoal: { goalType: 'DAILY', unitType: 'UNKNOWN', units: 100 },
  targeting: {
    geoTargeting: { targetedLocations: [], excludedLocations: [] },
    inventoryTargeting: { targetedAdUnits: [], excludedAdUnits: [], targetedPlacementIds: [] },
    technologyTargeting: {
      deviceCapabilityTargeting: { targetedDeviceCapabilities: [], excludedDeviceCapabilities: [] },
      deviceCategoryTargeting: { targetedDeviceCategories: [], excludedDeviceCategories: [] },
      operatingSystemTargeting: { isTargeted: true, operatingSystems: [] }
    },
    customTargeting: {
      logicalOperator: 'OR',
      children: [{ attributes: { 'xsi:type': 'CustomCriteriaSet' }, logicalOperator: 'AND', children: [] }]
    }
  },
  creativeTargetings: []
}

const devMode = false

// ======================================================================================================================
//  ____  _   _ _     _  __  _     ___ _   _ _____ ___ _____ _____ __  __    ____ ____  _____    _  _____ ___ ___  _   _
// | __ )| | | | |   | |/ / | |   |_ _| \ | | ____|_ _|_   _| ____|  \/  |  / ___|  _ \| ____|  / \|_   _|_ _/ _ \| \ | |
// |  _ \| | | | |   | ' /  | |    | ||  \| |  _|  | |  | | |  _| | |\/| | | |   | |_) |  _|   / _ \ | |  | | | | |  \| |
// | |_) | |_| | |___| . \  | |___ | || |\  | |___ | |  | | | |___| |  | | | |___|  _ <| |___ / ___ \| |  | | |_| | |\  |
// |____/ \___/|_____|_|\_\ |_____|___|_| \_|_____|___| |_| |_____|_|  |_|  \____|_| \_\_____/_/   \_\_| |___\___/|_| \_|
//
// ======================================================================================================================

export const BulkLineItemCreation = () => {
  const {
    errorMessage: serverErrorMessage,
    adRoots,
    advertisers,
    placements,
    customTargets,
    deviceCapabilities,
    deviceCategories,
    operatingSystems,
    loading
  } = useSelector(state => state.dfp)
  const dispatch = useDispatch()
  const commonClasses = commonStyle()
  const classes = useStyles()
  const { user } = useSelector(state => state.authReducer)
  const initialOrder = {
    name: '',
    advertiserId: '39030517', // z_test id
    order: devMode ? { id: '2719033624' } : null, // existing order, used for testing
    user
  }
  const [namedIndexCustomTargets, setNamedIndexCustomTargets] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [order, setOrder] = useState(JSON.parse(JSON.stringify(initialOrder)))
  const [lineItem, setLineItem] = useState(JSON.parse(JSON.stringify(initialLineItem)))
  const [priorityList, setPriorityList] = useState(range(lineItemOptions[lineItem.lineItemType].priorityOptions.min, lineItemOptions[lineItem.lineItemType].priorityOptions.max))
  const [advertiserList, setAdvertiserList] = useState([])
  const [placementList, setPlacementList] = useState([])
  const [creativeTargetingData, setCreativeTargetingData] = useState([])
  const [lineItemTargeting, setLineItemTargeting] = useState([{ customTarget: JSON.parse(JSON.stringify(initialCustomTarget)) }])
  const [adUnitSizes, setAdUnitSizes] = useState([])
  const [validAdUnitSizes, setValidAdUnitSizes] = useState([])
  const [adUnitTargetings, setAdUnitTargetings] = useState([])
  const [file, setFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState({})
  const [createdLineItems, setCreatedLineItems] = useState([])
  let fileInputElement = null
  let processedKeyValues = []

  // =======================================================================================
  //  ____  ____   ___   ____ _____ ____ ____     ____ ______     __  _____ ___ _     _____
  // |  _ \|  _ \ / _ \ / ___| ____/ ___/ ___|   / ___/ ___\ \   / / |  ___|_ _| |   | ____|
  // | |_) | |_) | | | | |   |  _| \___ \___ \  | |   \___ \\ \ / /  | |_   | || |   |  _|
  // |  __/|  _ <| |_| | |___| |___ ___) |__) | | |___ ___) |\ V /   |  _|  | || |___| |___
  // |_|   |_| \_\\___/ \____|_____|____/____/   \____|____/  \_/    |_|   |___|_____|_____|
  //
  // =======================================================================================
  const processCSVFile = () => {
    const itemsToPrepare = []
    const reader = new window.FileReader()

    reader.onload = (event) => {
      try {
        const data = event.target.result
        // console.log('data', data)
        const rows = cleanCut(data, '\n')
        // console.log('rows', rows)
        const fields = cleanCut(rows[0])
        // console.log('Fields: ', fields)
        rows.forEach((row, index) => {
          let keyValues = {}
          const columns = cleanCut(row)
          console.log('columns', columns)
          const lineItemDetail = mergeArrayIntoObject(JSON.parse(JSON.stringify(columns)), fields)
          console.log('lid: ', JSON.stringify(lineItemDetail))
          if (index > 0 && lineItemDetail.name !== 'template_sample') {
            const newLineItem = { ...JSON.parse(JSON.stringify(lineItem)), name: JSON.parse(JSON.stringify(lineItemDetail)).name }
            // console.log('New Line Item: ', newLineItem)
            const sizes = cleanCut(JSON.parse(JSON.stringify(lineItemDetail)).size, ';')
            // console.log('Sizes:', sizes)
            sizes.forEach(size => {
              const dimensions = cleanCut(size, 'x')
              // console.log('Dimensions:', dimensions)
              newLineItem.creativePlaceholders.push({ size: { width: parseInt(dimensions[0], 10), height: parseInt(dimensions[1], 10) } })
            })

            try{
              const rate = Math.round(parseFloat(JSON.parse(JSON.stringify(lineItemDetail)).rate) * 1000000) // For US dollars, $1 is 1,000,000 micros
              newLineItem.costPerUnit.microAmount = rate.toString()
            } catch(err) {
              console.log('Rate Error: ', err)
            }

            try{
              const targetKeyValues = cleanCut(JSON.parse(JSON.stringify(lineItemDetail)).targetKeyValues, ';')
              targetKeyValues.forEach(targetKeyValue => {
                const entry = cleanCut(targetKeyValue, '=')
                const key = entry[0]
                const values = entry[1]
                keyValues[key] = cleanCut(values, '+')
              })
            } catch(err) {
              console.log('Custom Targeting Error: ', err)
            }
            console.log('Key Values: ', JSON.parse(JSON.stringify(keyValues)))
            try{
              const geoTargets = cleanCut(JSON.parse(JSON.stringify(lineItemDetail)).geoTargets, ';')
              geoTargets.forEach((location) => {
                switch (location) {
                  case '': break
                  case 'US': newLineItem.targeting.geoTargeting.targetedLocations.push({ id: '2840', type: 'COUNTRY', canonicalParentId: '0', displayName: 'United States' }); break
                  case 'US-CA': newLineItem.targeting.geoTargeting.targetedLocations.push({ id: '21137', type: 'STATE', canonicalParentId: '2840', displayName: 'California, United States' }); break
                  case 'CA': newLineItem.targeting.geoTargeting.targetedLocations.push({ id: '2124', type: 'COUNTRY', canonicalParentId: '0', displayName: 'Canada' }); break
                  case 'GB': newLineItem.targeting.geoTargeting.targetedLocations.push({ id: '2826', type: 'COUNTRY', canonicalParentId: '0', displayName: 'United Kingdom' }); break
                  case '!US': newLineItem.targeting.geoTargeting.excludedLocations.push({ id: '2840', type: 'COUNTRY', canonicalParentId: '0', displayName: 'United States' }); break
                  case '!CA': newLineItem.targeting.geoTargeting.excludedLocations.push({ id: '2124', type: 'COUNTRY', canonicalParentId: '0', displayName: 'Canada' }); break
                  case '!GB': newLineItem.targeting.geoTargeting.excludedLocations.push({ id: '2826', type: 'COUNTRY', canonicalParentId: '0', displayName: 'United Kingdom' }); break
                  case '!US-CA': newLineItem.targeting.geoTargeting.excludedLocations.push({ id: '21137', type: 'STATE', canonicalParentId: '2840', displayName: 'California, United States' }); break
                  default: throw new Error(`${location} is not an available geoTarget`)
                }
              })
            } catch(err){
              console.log('Geo-Targeting Error : ', err)
            }
            // console.log('Pushing New Line Item:', newLineItem)
            itemsToPrepare.push(JSON.parse(JSON.stringify(newLineItem)))
          }
          if (Object.keys(keyValues).length > 0) processedKeyValues.push(keyValues)
        })

        const names = itemsToPrepare.map(item => item.name)
        const distinctNames = [...new Set(names)]
        if (distinctNames.length < names.length) {
          throw new Error('Duplicate Line Item names detected')
        }
        prepareLineItems(itemsToPrepare)
      } catch (error) {
        swal.fire({
          title: 'CSV File Processing Error',
          text: error.message,
          icon: 'error'
        })
      }
    }

    reader.readAsText(file)
  }

  // =================================================================================================
  //   ____  ____  _____ ____   _    ____  _____   _     ___ _   _ _____ ___ _____ _____ __  __ ____
  //  |  _ \|  _ \| ____|  _ \ / \  |  _ \| ____| | |   |_ _| \ | | ____|_ _|_   _| ____|  \/  / ___|
  //  | |_) | |_) |  _| | |_) / _ \ | |_) |  _|   | |    | ||  \| |  _|  | |  | | |  _| | |\/| \___ \
  //  |  __/|  _ <| |___|  __/ ___ \|  _ <| |___  | |___ | || |\  | |___ | |  | | | |___| |  | |___) |
  //  |_|   |_| \_\_____|_| /_/   \_\_| \_\_____| |_____|___|_| \_|_____|___| |_| |_____|_|  |_|____/
  //
  // =================================================================================================
  const prepareLineItems = async (items) => {
    // console.log('Preparing LineItems:', JSON.parse(JSON.stringify(items)))
    const results = await Promise.all(items.map((item, index) => {
      return new Promise((resolve, reject) => {
        if (placementList.length > 0) {
          item.targeting.inventoryTargeting.targetedPlacementIds = placementList.map(placement => placement?.id)
        }

        adUnitTargetings.forEach(adUnitTargeting => {
          if (adUnitTargeting.adUnits.length > 0) {
            const targeting = { attributes: { 'xsi:type': 'AdUnitTargeting' }, adUnitId: adUnitTargeting.adUnitId }
            if (adUnitTargeting.includeDescendants) {
              item.targeting.inventoryTargeting.targetedAdUnits.push(targeting)
            } else {
              item.targeting.inventoryTargeting.excludedAdUnits.push(targeting)
            }
          }
        })

        let hasInvTargeting = false
        for (const property in item.targeting.inventoryTargeting) {
          if (item.targeting.inventoryTargeting[property].length > 0) {
            hasInvTargeting = true
          }
        }
        if (!hasInvTargeting) {
          reject(new Error('No Ad Unit or Placement selected!'))
        }

        // set the ad unit sizes
        if (item.creativePlaceholders.length === 0) {
          // size not already set by csv
          let selectedSizes = 0
          validAdUnitSizes.forEach((size, index) => {
            if (adUnitSizes[index]) {
              // this size is selected, so add it
              item.creativePlaceholders.push({ size })
              selectedSizes++
            }
          })
          if (selectedSizes === 0) {
            reject(new Error('No Ad Unit size selected!'))
          }
        }

        // set endDateTime
        if (item.lineItemType.match(/^(SPONSORSHIP|NETWORK|PRICE_PRIORITY|HOUSE)$/)) {
          item.unlimitedEndDateTime = true
        }

        // clear units if goal is unknown
        if (item.primaryGoal.goalType === goalTypeEnum.NONE) {
          delete item.primaryGoal.unitType
          delete item.primaryGoal.units
        }

        if (item.primaryGoal.unitType === unitTypeEnum.UNKNOWN) {
          delete item.primaryGoal.unitType
        }

        // initialize creative targetings
        creativeTargetingData.forEach((targetingData) => {
          item.creativeTargetings.push({
            name: targetingData.name,
            targeting: {
              technologyTargeting: {
                deviceCapabilityTargeting: { targetedDeviceCapabilities: [], excludedDeviceCapabilities: [] },
                deviceCategoryTargeting: { targetedDeviceCategories: [], excludedDeviceCategories: [] },
                operatingSystemTargeting: { isTargeted: true, operatingSystems: [] }
              },
              customTargeting: {
                logicalOperator: 'OR',
                children: [{ attributes: { 'xsi:type': 'CustomCriteriaSet' }, logicalOperator: 'AND', children: [] }]
              }
            }
          })
        })
        /* LINE ITEM CUSTOM TARGETS */
        /* This must use the key/value names to support assigning these values via csv */
        const defaultTargeting = lineItemTargeting[0]
        console.log('Item/Index: ', { item, index })
        console.log('processedKeyValues: ', processedKeyValues)
        let keyValues = processedKeyValues[index]
        if (defaultTargeting.customTarget.key && defaultTargeting.customTarget.value && !keyValues[defaultTargeting.customTarget.key.name]) {
          keyValues[defaultTargeting.customTarget.key.name] = [defaultTargeting.customTarget.value.name]
        }
        console.log('KeyValues: ', keyValues)
        if (convertObjectToArray(keyValues).length === 0) {
          delete item.targeting.customTargeting
        } else {
          for (const keyName in keyValues) {
            if (keyName !== '') {
              const values = keyValues[keyName]
              const customTargetObj = namedIndexCustomTargets[formatKey(keyName)] ? namedIndexCustomTargets[formatKey(keyName)] : null
              const valueIds = values.map(value => {
                console.log('Value: ', value)
                let obj = customTargetObj.values.filter(item => {
                  if (item.name === value) console.log('Item Name: ', item.name)
                  return item.name === value
                })
                console.log('Obj: ', obj)
                if (obj.length > 0) return obj[0].id
                reject(new Error('Invalid Custom Target!'))
              })
              if (customTargetObj && valueIds.length > 0) {                
                const customCriteria = {
                  attributes: { 'xsi:type': 'CustomCriteria' },
                  keyId: customTargetObj?.id,
                  valueIds: valueIds,
                  operator: keyName.match(/^!/) ? 'IS_NOT' : 'IS'
                }
                console.log('Custom Criteria: ', customCriteria)
                item.targeting.customTargeting.children[0].children.push(customCriteria)                
              } else {
                reject(new Error('Invalid Custom Target!'))
              }
            }
          }
          if (item.targeting.customTargeting.children[0].children.length === 0) {
            delete item.targeting.customTargeting
          }
        }

        /* CREATIVE CUSTOM TARGETS */
        /* These cannot be set via csv, so we can assign them directly */
        creativeTargetingData.forEach((targetingData, index) => {
          if (targetingData.customTarget.key && targetingData.customTarget.value) {
            const customCriteria = {
              attributes: { 'xsi:type': 'CustomCriteria' },
              keyId: targetingData.customTarget.key?.id,
              valueIds: [targetingData.customTarget.value?.id],
              operator: targetingData.customTarget.exclude ? 'IS_NOT' : 'IS'
            }
            item.creativeTargetings[index].targeting.customTargeting.children[0].children.push(customCriteria)
          }
        })
        setOtherTargetings(defaultTargeting, item.targeting)

        creativeTargetingData.forEach((targetingData, index) => {
          setOtherTargetings(targetingData, item.creativeTargetings[index].targeting)
        })
        console.log('~~~~~~~~~~> Final Item: ', item)
        // reject(new Error('This a temporary end.  Check the logs.'))

        resolve(item)
      }).catch(error => {
        swal.fire({
          title: 'Line Item Processing Error',
          text: error.message,
          icon: 'error'
        })
      })
    }))

    const filteredResults = results.filter(item => item)
    if (filteredResults.length === results.length) {
      sendPayload(results)
    }
  }

  // ========================================================================================
  //     _    ____ _____ ___ ___  _   _   _____ _   _ _   _  ____ _____ ___ ___  _   _ ____
  //    / \  / ___|_   _|_ _/ _ \| \ | | |  ___| | | | \ | |/ ___|_   _|_ _/ _ \| \ | / ___|
  //   / _ \| |     | |  | | | | |  \| | | |_  | | | |  \| | |     | |  | | | | |  \| \___ \
  //  / ___ \ |___  | |  | | |_| | |\  | |  _| | |_| | |\  | |___  | |  | | |_| | |\  |___) |
  // /_/   \_\____| |_| |___\___/|_| \_| |_|    \___/|_| \_|\____| |_| |___\___/|_| \_|____/
  //
  // ========================================================================================

  const resetForm = () => {
    setOrder(JSON.parse(JSON.stringify(initialOrder)))
    setLineItem(JSON.parse(JSON.stringify(initialLineItem)))
    setPriorityList(range(lineItemOptions[lineItem.lineItemType].priorityOptions.min, lineItemOptions[lineItem.lineItemType].priorityOptions.max))
    setAdvertiserList([])
    setPlacementList([])
    setCreativeTargetingData([])
    setLineItemTargeting([{ customTarget: JSON.parse(JSON.stringify(initialCustomTarget)) }])
    setAdUnitSizes([])
    setValidAdUnitSizes([])
    setAdUnitTargetings([])
    setFile(null)
    setErrorMessage({})
    setCreatedLineItems([])
    keyValues = {}
    fileInputElement = null
  }

  const sendPayload = (items) => {
    if (items.length === 0) {
      swal.fire({
        title: 'Warning',
        text: 'There are no Line Items',
        icon: 'warning'
      })
      return
    }
    const payload = {
      order,
      lineItems: items
    }

    // // console.log('payload', payload)
    request('/dfp/lineItems/bulkCreate', 'POST', { data: payload }, true).then(res => {
      if (res.data) {
        if (res.data.createdItems) {
          swal.fire({
            title: 'Success',
            text: 'Order Processed Successfully',
            icon: 'success'
          })
          setCreatedLineItems(res.data.createdItems)
        } else {
          swal.fire({
            title: 'Request Error',
            text: res.data.reason,
            icon: 'error'
          })
        }
      }
    })
  }

  const submitOrder = () => {
    setErrorMessage({})
    if (order.name === '' && !devMode) {
      setErrorMessage({ ...errorMessage, order: 'Order name is required.' })
      return
    }

    lineItem.primaryGoal.units = lineItem.primaryGoal.units > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : lineItem.primaryGoal.units
    lineItem.primaryGoal.units = lineItem.primaryGoal.units < 0 ? '0' : lineItem.primaryGoal.units.toString()
    lineItem.priority = parseInt(lineItem.priority)
    if (!file) {
      swal.fire({
        title: 'Warning',
        text: 'CSV File not attached',
        icon: 'warning'
      })
      return
    }
    try {
      processCSVFile()
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error,
        icon: 'error'
      })
    }
  }

  const addAdUnitTargeting = () => {
    setAdUnitTargetings([...adUnitTargetings, { id: Date.now(), adUnitId: '', includeDescendants: true, adUnits: [] }])
  }

  const updateAdSizes = (adUnitTargetings) => {
    let allAdSizes = []
    adUnitTargetings.forEach(item => {
      const adUnit = item.adUnits[item.adUnits.length - 1]
      if (adUnit) {
        const sizes = item.includeDescendants ? adUnit.adUnitSizes.map(size => { return { ...size, checked: false } }) : []
        allAdSizes = allAdSizes.concat(sizes)
      }
    })
    allAdSizes = allAdSizes.map(item => JSON.stringify(item))
    allAdSizes = [...new Set(allAdSizes)]
    allAdSizes = allAdSizes.map(item => JSON.parse(item))
    setAdUnitSizes(allAdSizes)
  }

  const addCreativeTargeting = () => {
    setCreativeTargetingData([...creativeTargetingData, { id: Date.now(), name: '', customTarget: JSON.parse(JSON.stringify(initialCustomTarget)) }])
  }

  const openInstructionsDialog = () => {
    const dialog = {
      title: 'Additional Information',
      content: (
        <Box p={2}>
          {displayInstructionSet(generalInformation)}
          {displayInstructionSet(csvFileInformation)}
          {displayInstructionSet(formInformation)}
        </Box>
      )
    }
    setDialog(dialog)
    setOpenDialog(true)
  }

  useEffect(() => {
    if (adRoots.length === 0) { dispatch(fetchAllAdUnits()) }
    if (advertisers.length === 0) { dispatch(getAllAdvertisers()) }
    if (placements.length === 0) { dispatch(getAllPlacements()) }
    if (customTargets.length === 0) { dispatch(getAllCustomTargets(true)) }
    if (deviceCapabilities.length === 0) { dispatch(getDeviceCapabilities()) }
    if (deviceCategories.length === 0) { dispatch(getDeviceCategories()) }
    if (operatingSystems.length === 0) { dispatch(getOperatingSystems()) }
  }, [])

  useEffect(() => {
    if (serverErrorMessage) {
      swal.fire({
        title: 'Error',
        text: serverErrorMessage,
        icon: 'error'
      })
    }
  }, [serverErrorMessage])

  useEffect(() => {
    // // console.log('customTargets', customTargets)
    if (customTargets) { setNamedIndexCustomTargets(indexArrayByObjectName(customTargets)) }
  }, [customTargets])

  // =============================================================================================
  //  ____ ___ ____  ____  _        _ __   __  _____ _   _ _   _  ____ _____ ___ ___  _   _ ____
  // |  _ \_ _/ ___||  _ \| |      / \\ \ / / |  ___| | | | \ | |/ ___|_   _|_ _/ _ \| \ | / ___|
  // | | | | |\___ \| |_) | |     / _ \\ V /  | |_  | | | |  \| | |     | |  | | | | |  \| \___ \
  // | |_| | | ___) |  __/| |___ / ___ \| |   |  _| | |_| | |\  | |___  | |  | | |_| | |\  |___) |
  // |____/___|____/|_|   |_____/_/   \_\_|   |_|    \___/|_| \_|\____| |_| |___\___/|_| \_|____/
  //
  // =============================================================================================

  const displayAdUnitRow = (adUnitTargeting, index) => {
    return (
      <ListItem disableGutters key={adUnitTargeting?.id}>
        <IconButton
          className={commonClasses.clrDanger}
          aria-label='delete'
          onClick={() => {
            const _adUnitTargetings = adUnitTargetings.filter(item => item?.id !== adUnitTargeting?.id)
            setAdUnitTargetings(_adUnitTargetings)
            updateAdSizes(_adUnitTargetings)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <Select
          className={addClasses(commonClasses.mr_1, commonClasses.ml_1)}
          value={adUnitTargetings[index].includeDescendants}
          onChange={(event) => {
            adUnitTargetings[index].includeDescendants = event.target.value
            setAdUnitTargetings(adUnitTargetings)
            updateAdSizes(adUnitTargetings)
          }}
        >
          <MenuItem value>Include</MenuItem>
          <MenuItem value={false}>Exclude</MenuItem>
        </Select>
        {[0, 1, 2, 3, 4].map(level => {
          if (level > 0 && !adUnitTargeting.adUnits[level - 1]) { return null }
          if (level > 0 && !adUnitTargeting.adUnits[level - 1].hasChildren) { return null }
          const options = level === 0 ? adRoots : adUnitTargeting.adUnits[level - 1].children
          if (!options) { return null }
          const nullOption = { name: 'None Selected', adUnitSizes: [] }
          const value = adUnitTargeting.adUnits[level] ? adUnitTargeting.adUnits[level] : nullOption
          return (
            <Autocomplete
              className={addClasses(commonClasses.mr_1)}
              style={{ minWidth: 60, width: ((value.name.length * 14)), maxWidth: 200 }}
              key={level}
              value={value}
              autoComplete
              disableClearable
              options={[nullOption, ...options]}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                adUnitTargeting.adUnits[level] = newValue
                // // console.log('newValue', newValue)
                adUnitTargetings[index].adUnitId = adUnitTargeting.adUnits[adUnitTargeting.adUnits.length - 1]._id
                setAdUnitTargetings(adUnitTargetings)
                updateAdSizes(adUnitTargetings)
              }}
              renderInput={(params) => { return (<TextField {...params} variant='standard' />) }}
            />
          )
        })}
      </ListItem>
    )
  }

  const displayAdUnitSizes = () => {
    return (
      <Box className={classes.displayFlex}>
        <Typography className={addClasses(commonClasses.p_1)}>Sizes: </Typography>
        <FormGroup row>
          {adUnitSizes.map((size, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    className={addClasses(commonClasses.p_1)}
                    color='primary'
                    checked={size.checked}
                    onChange={() => {
                      size.checked = !size.checked
                      setValidAdUnitSizes(adUnitSizes.filter(item => item.checked))
                    }}
                  />
                }
                label={size.name}
              />
            )
          })}
        </FormGroup>
      </Box>
    )
  }

  const displayCreativeTargetings = () => {
    return (
      <Box className={addClasses(commonClasses.mb_2)}>
        {creativeTargetingData.length > 0
          ? (
            <List disablePadding dense>
              {creativeTargetingData.map(creativeTargeting => {
                return (
                  <ListItem disableGutters style={{ display: 'block' }} key={creativeTargeting?.id}>
                    <TextField
                      style={{ minWidth: '360px' }}
                      variant='filled'
                      size='small'
                      margin='none'
                      label='Name'
                      onChange={(event) => {
                        creativeTargeting.name = event.target.value
                        setCreativeTargetingData(creativeTargetingData)
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              className={addClasses(commonClasses.clrDanger)}
                              edge='end'
                              onClick={() => {
                                setCreativeTargetingData(creativeTargetingData.filter(item => item?.id !== creativeTargeting?.id))
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <LineItemTargeting
                      data={creativeTargetingData}
                      setData={setCreativeTargetingData}
                      customTargets={customTargets}
                      deviceCapabilities={deviceCapabilities}
                      deviceCategories={deviceCategories}
                      operatingSystems={operatingSystems}
                      self={creativeTargeting}
                    />
                  </ListItem>
                )
              })}
            </List>
            )
          : null}
      </Box>
    )
  }

  const displayInstructionSet = ({ title, instructions }) => {
    return (
      <List subheader={title} dense>
        {instructions.map((item, index) => {
          const number = index + 1
          return (<ListItem dense key={index}>{number + '. ' + item}</ListItem>)
        })}
      </List>
    )
  }

  const displayTitle = () => {
    return (
      <>
        <Typography className={classes.plainText}>
          Bulk Line Item Creation
          <IconButton
            className={commonClasses.ml_1}
            aria-label='Additional Information'
            title='Additional Information'
            onClick={() => openInstructionsDialog()}
          >
            <InfoIcon />
          </IconButton>
        </Typography>
      </>
    )
  }

  // ====================================================================================
  //  ____  _____ _   _ ____  _____ ____    _____ _   _ _   _  ____ _____ ___ ___  _   _
  // |  _ \| ____| \ | |  _ \| ____|  _ \  |  ___| | | | \ | |/ ___|_   _|_ _/ _ \| \ | |
  // | |_) |  _| |  \| | | | |  _| | |_) | | |_  | | | |  \| | |     | |  | | | | |  \| |
  // |  _ <| |___| |\  | |_| | |___|  _ <  |  _| | |_| | |\  | |___  | |  | | |_| | |\  |
  // |_| \_\_____|_| \_|____/|_____|_| \_\ |_|    \___/|_| \_|\____| |_| |___\___/|_| \_|
  //
  // ====================================================================================

  return (
    <Container maxWidth='lg'>
      <Card>
        <CardHeader
          title={displayTitle()}
          action={createdLineItems.length === 0
            ? (
              <Button
                className={commonClasses.btnInfo}
                variant='contained'
                startIcon={<GetAppIcon />}
                onClick={(event) => {
                  event.preventDefault()
                  request('/bulk_line_item_template').then(res => {
                  // // console.log('res', res)
                    const data = new window.Blob([res.data], { type: 'text/csv' })
                    const link = document.createElement('a')
                    link.download = 'bulk_line_item_template.csv'
                    link.href = URL.createObjectURL(data)
                    link.click()
                  })
                }}
              >
                Download Template
              </Button>
              )
            : (
              <Button
                className={commonClasses.btnInfo}
                variant='contained'
                onClick={resetForm}
              >
                Create More Line Items
              </Button>
              )}
        />
        <Divider />
        {!loading
          ? (
            <>
              <CardContent className={commonClasses.cardBkClr}>
                {createdLineItems.length === 0
                  ? (
                    <>
                      <Box>
                        <Card>
                          <CardContent>
                            <FormGroup row className={addClasses(classes.justifySpaceBetween, commonClasses.mb_2)}>
                              <TextField
                                className={addClasses(classes.flexGrow, commonClasses.mr_2)}
                                error={!!errorMessage.order}
                                helperText={errorMessage.order}
                                style={{ minWidth: '300px' }}
                                label='Order Name'
                                margin='none'
                                onChange={(event) => {
                                  errorMessage.order = event.target.value.length > 0 ? false : 'Order name is required.'
                                  setErrorMessage({ ...errorMessage })
                                  setOrder({ ...order, name: event.target.value })
                                }}
                              />
                              <Autocomplete
                                style={{ width: '300px' }}
                                autoSelect
                                autoComplete
                                forcePopupIcon={false}
                                noOptionsText='Type to search or leave blank for z_test'
                                options={advertiserList}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => setOrder({ ...order, advertiserId: (newValue ? newValue.id : '39030517') })}
                                onInputChange={(event, newValue, reason) => setAdvertiserList((reason === 'input' && newValue.length >= 3) ? advertisers.filter(advertiser => advertiser.name.match(RegExp(`^${newValue}`, 'i'))).sort((a, b) => a.name > b.name) : [])}
                                renderInput={(params) => (<TextField {...params} label='Advertiser' variant='standard' />)}
                              />
                            </FormGroup>
                          </CardContent>
                        </Card><br />
                        <Card>
                          <CardContent>
                            <Typography className={addClasses(classes.heading, commonClasses.mb_1)}>Line Item Defaults</Typography>
                            <Divider />
                            <Box p={2}>
                              <FormGroup className={addClasses(classes.spaceBetween, commonClasses.mb_2)} row>
                                <Autocomplete
                                  className={addClasses(commonClasses.mr_2)}
                                  style={{ minWidth: 200 }}
                                  disableClearable
                                  value={lineItem.lineItemType}
                                  options={lineItemTypeList}
                                  getOptionLabel={(option) => formatText(option)}
                                  onChange={(event, newValue) => {
                                    setLineItem({
                                      ...lineItem,
                                      lineItemType: newValue,
                                      priority: lineItemOptions[newValue].priorityOptions.default.toString(),
                                      costType: lineItemOptions[newValue].costTypes[0],
                                      primaryGoal: { ...lineItem.primaryGoal, goalType: lineItemOptions[newValue].goalTypes[0], unitType: lineItemOptions[newValue].unitTypes[0] }
                                    })
                                    setPriorityList(range(lineItemOptions[newValue].priorityOptions.min, lineItemOptions[newValue].priorityOptions.max))
                                  }}
                                  renderInput={(params) => (<TextField {...params} label='Type' variant='standard' />)}
                                />
                                <Autocomplete
                                  className={addClasses(commonClasses.mr_2)}
                                  style={{ minWidth: 80 }}
                                  disableClearable
                                  value={lineItem.priority.toString()}
                                  options={priorityList}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, newValue) => setLineItem({ ...lineItem, priority: newValue })}
                                  renderInput={(params) => (<TextField {...params} label='Priority' variant='standard' />)}
                                />
                                {lineItem.costType !== costTypeEnum.UNKNOWN
                                  ? (
                                    <Autocomplete
                                      className={addClasses(commonClasses.mr_2)}
                                      style={{ minWidth: 120 }}
                                      disableClearable
                                      value={lineItem.costType}
                                      options={lineItemOptions[lineItem.lineItemType].costTypes}
                                      getOptionLabel={(option) => option}
                                      onChange={(event, newValue) => setLineItem({ ...lineItem, costType: newValue })}
                                      renderInput={(params) => (<TextField {...params} label='Cost Type' variant='standard' />)}
                                    />
                                    )
                                  : <TextField className={addClasses(commonClasses.mr_2)} style={{ width: 80 }} label='Cost Type' defaultValue='N/A' disabled />}
                                <Autocomplete
                                  className={addClasses(commonClasses.mr_2)}
                                  style={{ minWidth: 100 }}
                                  disableClearable
                                  value={lineItem.primaryGoal.goalType}
                                  options={lineItemOptions[lineItem.lineItemType].goalTypes}
                                  getOptionLabel={(option) => formatText(option)}
                                  onChange={(event, newValue) => setLineItem({ ...lineItem, primaryGoal: { ...lineItem.primaryGoal, goalType: newValue } })}
                                  renderInput={(params) => (<TextField {...params} label='Goal Type' variant='standard' />)}
                                />
                                {lineItem.primaryGoal.unitType !== unitTypeEnum.UNKNOWN
                                  ? (
                                    <Autocomplete
                                      className={addClasses(commonClasses.mr_2)}
                                      style={{ minWidth: 180 }}
                                      disableClearable
                                      value={lineItem.primaryGoal.unitType}
                                      options={lineItemOptions[lineItem.lineItemType].unitTypes}
                                      getOptionLabel={(option) => formatText(option)}
                                      onChange={(event, newValue) => setLineItem({ ...lineItem, primaryGoal: { ...lineItem.primaryGoal, unitType: newValue } })}
                                      renderInput={(params) => (<TextField {...params} label='Unit Type' variant='standard' />)}
                                    />
                                    )
                                  : null}
                                <TextField
                                  margin='normal'
                                  inputProps={{
                                    type: 'number',
                                    min: 0,
                                    max: Number.MAX_SAFE_INTEGER
                                  }}
                                  value={lineItem.primaryGoal.units}
                                  onChange={(event) => setLineItem({ ...lineItem, primaryGoal: { ...lineItem.primaryGoal, units: event.target.value } })}
                                  InputProps={{
                                    endAdornment: lineItem.lineItemType === lineItemTypeEnum.SPONSORSHIP ? (<InputAdornment position='end'>% Share of Voice</InputAdornment>) : (<InputAdornment position='end'>Units</InputAdornment>)
                                  }}
                                />
                              </FormGroup>
                              <FormGroup className={addClasses(classes.justifyFlexStart, commonClasses.mb_2)} row>
                                <Autocomplete
                                  className={addClasses(commonClasses.mr_2)}
                                  style={{ minWidth: 300 }}
                                  autoSelect
                                  autoComplete
                                  multiple
                                  disableClearable
                                  options={placements}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, newValue) => setPlacementList(newValue)}
                                  renderInput={(params) => (<TextField {...params} label='Placement Targeting' variant='standard' />)}
                                />
                                <FormControlLabel
                                  label='Allow Same Advertiser Exception'
                                  control={(
                                    <Checkbox
                                      color='primary'
                                      checked={lineItem.disableSameAdvertiserCompetitiveExclusion}
                                      onChange={(event) => setLineItem({ ...lineItem, disableSameAdvertiserCompetitiveExclusion: event.target.checked })}
                                    />
                              )}
                                />
                              </FormGroup>
                            </Box>
                          </CardContent>
                        </Card><br />
                        <Card>
                          <CardContent>
                            <Box>
                              <Typography className={addClasses(classes.heading, commonClasses.mb_1)}>
                                Ad Unit Targeting
                                <IconButton
                                  className={commonClasses.ml_1}
                                  aria-label='Add'
                                  title='Add'
                                  onClick={addAdUnitTargeting}
                                >
                                  <AddBoxIcon />
                                </IconButton>
                              </Typography>
                            </Box>
                            <Box className={addClasses(commonClasses.mb_2)}>
                              {adUnitTargetings.length > 0
                                ? (
                                  <List disablePadding dense>
                                    {adUnitTargetings.map((adUnitTargeting, index) => displayAdUnitRow(adUnitTargeting, index))}
                                    {adUnitSizes.length > 0 ? displayAdUnitSizes() : null}
                                  </List>
                                  )
                                : null}
                            </Box>
                            </CardContent>
                        </Card><br />
                        <Card>
                          <CardContent>
                            <Box>
                              <Typography className={addClasses(classes.heading, commonClasses.mb_1)}>
                                Line Item Targeting
                              </Typography>
                            </Box>
                            <Box>
                              <LineItemTargeting
                                data={lineItemTargeting}
                                setData={setLineItemTargeting}
                                customTargets={customTargets}
                                deviceCapabilities={deviceCapabilities}
                                deviceCategories={deviceCategories}
                                operatingSystems={operatingSystems}
                                self={lineItemTargeting[0]}
                              />
                            </Box>
                          </CardContent>
                        </Card><br />
                        <Card>
                          <CardContent>
                            <Box>
                              <Typography className={addClasses(classes.heading, commonClasses.mb_1)}>
                                Creative Targetings
                                <IconButton
                                  className={commonClasses.ml_1}
                                  aria-label='Add'
                                  title='Add'
                                  onClick={addCreativeTargeting}
                                >
                                  <AddBoxIcon />
                                </IconButton>
                              </Typography>
                            </Box>
                            {displayCreativeTargetings()}
                          </CardContent>
                        </Card><br />
                      </Box>
                    </>                
                    )
                  : (
                    <>
                      <Card>
                        <CardContent>
                          <Box>
                            <MaterialTable
                              columns={[
                                { title: 'Line Item Name', field: 'name' },
                                { title: 'Order ID', field: 'orderId' },
                                { title: 'Line Item ID', field: 'id' },
                                {
                                  title: 'URL',
                                  field: 'url',
                                  render: item => (<Link className={commonClasses.linkColor} href={item.url} target='_blank' rel='noopener noreferrer'>{item.url}</Link>)
                                }
                              ]}
                              data={createdLineItems.map(item => { return { ...item, url: `${GAM_BASE_URL}#delivery/LineItemDetail/orderId=${item.orderId}&lineItemId=${item?.id}` } })}
                              title='Created Line Items'
                              icons={TableIcons}
                              options={{
                                emptyRowsWhenPaging: false,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                exportButton: true,
                                exportFileName: `created_line_items_${Date.now()}.csv`
                              }}
                              actions={[
                                {
                                  icon: TableIcons.LaunchIcon,
                                  tooltip: 'Open Link In New Tab',
                                  onClick: (event, item) => {
                                    window.open(`${GAM_BASE_URL}#delivery/LineItemDetail/orderId=${item.orderId}&lineItemId=${item?.id}`)
                                  }
                                }
                              ]}
                            />
                          </Box>
                        </CardContent>
                      </Card><br />
                    </>
                    )}
              </CardContent>
              <CardActions>
                <Grid container direction='row' justify='flex-end'>
                  <Grid>{file ? <Typography>{file.name}</Typography> : null}</Grid>
                  <Grid>
                    <Button
                      className={addClasses(commonClasses.btnInfo, commonClasses.ml_1)}
                      variant='contained'
                      onClick={() => fileInputElement.click()}
                      startIcon={<SearchIcon />}
                    >
                      <input
                        style={{ display: 'none' }}
                        type='file'
                        accept='.csv'
                        required
                        ref={element => { fileInputElement = element }}
                        onChange={(event) => setFile(event.target.files[0])}
                      />
                      Choose File
                    </Button>
                    <Button
                      className={addClasses(commonClasses.btnSuccess, commonClasses.ml_1)}
                      variant='contained'
                      onClick={submitOrder}
                      startIcon={<PublishIcon />}
                    >
                      Upload File
                    </Button>
                  </Grid>
                </Grid>                    
              </CardActions>
            </>
          )
          : <LinearProgress />}
      </Card>
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
      />
    </Container>
  )
}
